import { useEffect } from 'react'
import Layout from '../Components/Layout'
// import contact from '.././img/contact.jpg'
import Form from '../Components/Form/Form'
// import Map from '../Components/Layout/Map'
import { RiPhoneLine } from 'react-icons/ri'
import { AiOutlineMail } from 'react-icons/ai'
import { GoLocation } from 'react-icons/go'
import { ImWhatsapp } from 'react-icons/im'
// import PageHeader from '../Components/UI/PageHeader/PageHeader'
import { useTranslation } from "react-i18next"
const ContactPage = () => {

  const { t } = useTranslation()
  useEffect(() => {
    document.title = t('contact_page')
  }, [t]);
  return (

    <Layout>
      {/* <PageHeader image={contact} title={t('CONTACT_US')} /> */}
      <section id="Contact" className='md:pt-80 px-4'>
        <div className="container mx-auto my-16">
          <div className="row flex items-center justify-center flex-wrap ">
            <div className="w-full lg  md:py-4 md:px-32 md:pl-0 mb-16">
              <div className="subTitle mb-4">
                <p className='text-2xl md:text-3xl flex items-center blueColor'>
                  <span className=' relative flex mr-4 overflow-hidden h-1 w-40'>.</span>
                  {t('CONTACT_US')}
                </p>
              </div>
              <div className="mainTitle blueColor">
                <h2> {t('CONTACT_US')}</h2>
              </div>
              <div className="my-4  blueColor">
                <p>{t('55')}</p>
              </div>
            </div>
            <div className="w-full  lg:w-1/3  md:py-4 md:px-32 md:pl-0 mb-16">
              <Form />
            </div>
            <div className="w-full  lg:w-1/3  md:py-4 md:px-32 md:pl-0 mb-16">
              <ul className='font-bold leading-loose text-center justify-center flex flex-col items-center md:items-start'>
                <li className='mb-8'>
                  <a href="mailto:info@brainclickads.com" className='text-gray-600 flex items-center justify-start text-2xl xl:text-3xl mb-6'> <AiOutlineMail className='mr-4 text-cyan-500' /> info@brainclickads.com</a>
                </li>
                <li className='mb-8 rtl:text-right ltr:text-left'>
                  <a href="https://www.google.com/maps/search/UK+business+address+is+337+Forest+Road,+Walthamstow,+E17+5JR/@51.5894289,-0.030033,89m/data=!3m1!1e3" className='text-gray-600 flex items-center justify-start text-2xl xl:text-3xl mb-6'>
                    <GoLocation className='mr-4 text-cyan-500' /> <span className='ltrDir'>{t('337 Forest Road, Walthamstow, E17 5JR')}</span> 
                  </a>
                </li>
                <li className='mb-8'>
                  <a href="https://api.whatsapp.com/send?phone=971529962679" target='_blank' rel='noreferrer' className='text-gray-600 flex items-center justify-start text-2xl xl:text-3xl mb-6 '> <ImWhatsapp className='mr-4 text-cyan-500' />   <span dir="ltr">+97152962679  </span> </a>
                </li>
                <li className='mb-8'>
                  <a href="tel:+971 4 568 7471" className='text-gray-600 flex items-center justify-start text-2xl xl:text-3xl mb-6'> <RiPhoneLine className='mr-4 text-cyan-500' />  <span dir="ltr">+971529962679</span> </a>
                </li>
              </ul>
            </div>
            <div className="w-full  lg:w-1/3  md:py-4 md:px-32 md:pl-0 mb-16">
              <ul className='font-bold leading-loose text-center justify-center flex flex-col items-center md:items-start'>
                <li className='mb-8'>
                  <a href="mailto:info@brainclickads.com" className='text-gray-600 flex items-center justify-start text-2xl xl:text-3xl mb-6'> <AiOutlineMail className='mr-4 text-cyan-500' /> info@brainclickads.com</a>
                </li>
                <li className='mb-8 rtl:text-right ltr:text-left'>
                  <a href="https://www.google.com/maps/place/Brain+Click+Ads/@25.1860377,55.2595437,19.25z/data=!4m12!1m6!3m5!1s0x0:0xc9891260a62ede50!2sBrain+Click+Ads!8m2!3d25.1859019!4d55.2602652!3m4!1s0x0:0xc9891260a62ede50!8m2!3d25.1859019!4d55.2602652" className='text-gray-600 flex items-center justify-start text-2xl xl:text-3xl mb-6'>
                    <GoLocation className='mr-4 text-cyan-500' /> {t('Iris Bay Tower 2107, Business bay,  Dubai, U.A.E.')}
                  </a>
                </li>
                <li className='mb-8'>
                  <a href="https://api.whatsapp.com/send?phone=971529962679" target='_blank' rel='noreferrer' className='text-gray-600 flex items-center justify-start text-2xl xl:text-3xl mb-6'> <ImWhatsapp className='mr-4 text-cyan-500' />   <span dir="ltr">+97152962679  </span> </a>
                </li>
                <li className='mb-8'>
                  <a href="tel:+971 4 568 7471" className='text-gray-600 flex items-center justify-start text-2xl xl:text-3xl mb-6'> <RiPhoneLine className='mr-4 text-cyan-500' />  <span dir="ltr">+971529962679</span> </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default ContactPage